// Import components
import HeroSection from '../assets/components/HeroSection';

const Landing = ({ programRef, timelineRef, galleryRef, aboutUsRef, contactRef, handleScroll }) => {
  return (
    <div>
        <HeroSection handleScroll={handleScroll} programRef={programRef} />
    </div>
  )
}

export default Landing