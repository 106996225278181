import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MainVideo from '../media/FRATPARTY_web.mp4'
import MainVideoMobile from '../media/FRATPARTY_mobile.mp4'

const HeroSection = ({ handleScroll, programRef }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const handleIframeLoad = () => {
    setIsLoading(false); // Remove loading spinner when iframe is loaded
  };

  return (
    <section className="relative w-full h-full ob overflow-hidden">
      <div className="max-h-screen flex h-screen">
          <video className="w-full h-full object-cover hidden sm:block"
          src={MainVideo}
          width="600"
          height="300"
          autoPlay
          loop
          muted
          title='Promotional Video'
          />
          <video className="w-full h-full object-cover sm:hidden"
          src={MainVideoMobile}
          width="600"
          height="300"
          autoPlay
          loop
          muted
          title='Promotional Video'
          />
      </div>

      <div className="absolute top-0 mt-[17rem] sm:max-2xl:mt-[-20.5rem] h-full w-full items-center justify-center flex">
        <button
          onClick={() => navigate('/tickets/1')}
          className="bg-white opacity-65 hover:opacity-100 text-[#171717] px-5 py-2 sm:max-2xl:px-5 md:max-2xl:py-4 rounded-full hover:bg-[#171717] hover:text-white text-xl flex items-center font-semibold gap-2">
          <span className='opacity-100 text-md'>Buy tickets now!</span>
          <i className="fi fi-ss-angle-down text-sm mt-2"></i>
        </button>
      </div>
    </section>
  );
};

export default HeroSection;
